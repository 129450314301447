function Footer() {
  return (
    <div id="footer">
      <a href="https://www.sarastrasner.com/" target="blank">
        <p>WHO MADE THIS AWESOME SITE?</p>
      </a>
    </div>
  );
}

export default Footer;
